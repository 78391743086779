import FetchClient, { AbortableRequest, FetchClientError, Problem } from '@cb/common-ts/FetchClient';
import UpdatePasswordRequest from '../Models/Request/UpdatePasswordRequest';
import UserPictureUploadRequest from '../Models/Response/UserPictureUploadRequest';
import User from '../Models/User';
import { ApiEndpoints } from '../Utils/NavigationUtils';
import SetupMfaRequest from '../Models/Request/SetupMfaRequest';
import RequestMfaSecretResponse from '../Models/Response/RequestMfaSecretResponse';
import { LoginResponseError } from './AuthService';

export default class UserService {
	private static readonly _fetchClient = new FetchClient();

	public static GetUserInfo(): AbortableRequest<User | undefined> {
		const request = this._fetchClient.getJson<User>(ApiEndpoints.Profile);
		return {
			abort: request.abort,
			response: (async () => {
				try {
					const response = await request.response;
					return response;
				} catch (err) {
					if (err instanceof FetchClientError && err.status === 401) {
						return undefined;
					}
					throw err;
				}
			})(),
		};
	}

	public static SaveUserInfo(user: User): Promise<User> {
		const { response } = this._fetchClient.postJson<User, User>(ApiEndpoints.Profile, user);
		return response;
	}

	public static async UpdatePassword(updatePasswordRequest: UpdatePasswordRequest) {
		try {
			const request = this._fetchClient.postJson<User, UpdatePasswordRequest>(ApiEndpoints.UpdatePassword, updatePasswordRequest);
			const response = await request.response;
			return response;
		} catch (err) {
			if (err instanceof Problem && err.status === 401) {
				return Promise.resolve(LoginResponseError[err.type as keyof typeof LoginResponseError]);
			}
			throw err;
		}
	}

	public static RequestMfaSecret(email: string) {
		return this._fetchClient.postJson<RequestMfaSecretResponse, { email: string }>(ApiEndpoints.RequestMfaSecret, {
			email,
		});
	}

	public static SetupMfa(request: SetupMfaRequest) {
		const { response } = this._fetchClient.postJson(ApiEndpoints.SetupMfa, request);
		return response;
	}
	public static DeleteMfa(currentPassword: string) {
		const { response } = this._fetchClient.postJson(ApiEndpoints.DeleteMfa, { password: currentPassword });
		return response;
	}

	public static async UploadPicture(file: File): Promise<User> {
		// Request a pre-signed URL (and postback) to upload to
		const { response } = this._fetchClient.getJson<UserPictureUploadRequest>(
			`/user/me/picture/upload?contentType=${encodeURIComponent(file.type)}`,
		);

		const urisResponse = await response;

		// Use a new fetch client which doesn't have the default headers set because they interfere with the Azure upload
		const headerlessFetchClient = new FetchClient({ optionsCallback: () => Promise.resolve<RequestInit>({}) });
		// Upload the file to Azure
		await headerlessFetchClient.put(urisResponse.uploadUri, file, {
			headers: {
				'content-type': file.type,
				'x-ms-blob-type': 'BlockBlob',
			},
		}).response;

		// Complete the postback to the server to signify success
		const request = this._fetchClient.postJson<User, unknown>(urisResponse.postbackUri, {});

		return request.response;
	}
}
