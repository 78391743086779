import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import { Scheme, Variant } from '@cb/solaris-react/Constants/System';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import PinInput, { PinInputRef } from '@cb/solaris-react/Components/Input/PinInput';

export type ValidateMfaModalProps = PropsWithClassName<{
	onSubmitRequested: (mfaCode: string) => Promise<boolean>;
}>;

export default function ValidateMfaModal(props: ValidateMfaModalProps) {
	const { onSubmitRequested } = props;
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const pinInputRef = useRef<PinInputRef | null>(null);
	const [mfaCode, setMfaCode] = useState('');

	const triggerSubmit = useCallback(async () => {
		const loader = addLoader();

		const success = await onSubmitRequested(mfaCode);
		if (!success) {
			setMfaCode('');
			removeLoader(loader);
			pinInputRef.current?.reset();
		}
	}, [addLoader, mfaCode, onSubmitRequested, removeLoader]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: Variant.quiet,
				text: 'Cancel',
				disabled: isLoading,
			},
			{
				scheme: Scheme.accent2,
				text: 'Continue',
				disabled: isLoading || mfaCode.length !== 6,
				callback: triggerSubmit,
			},
		],
		[isLoading, mfaCode.length, triggerSubmit],
	);

	const handlePinChanged = (value: string) => {
		if (value.length === 6) {
			setMfaCode(value);
		} else {
			setMfaCode('');
		}
	};

	return (
		<ModalContent
			title="Validate MFA"
			actions={actions}
			body={
				<form
					onSubmit={(e) => {
						e.preventDefault();
						triggerSubmit().catch((err) => {
							throw err;
						});
					}}
				>
					<input type="submit" hidden />
					<p>Please enter your TOPT code from your authenticator app.</p>
					<PinInput length={6} onChange={handlePinChanged} ref={pinInputRef} />
				</form>
			}
		/>
	);
}
