/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import StepperModalContent, {
	StepperModalContentItem,
} from '@cb/solaris-react/Components/Interactive/Modal/StepperModalContent';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import createQrCodeGenerator from 'qrcode-generator';
import styled, { css } from 'styled-components';
import Codeblock from '@cb/solaris-react/Components/Content/Codeblock';
import PinInput from '@cb/solaris-react/Components/Input/PinInput';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import UserService from '../../Services/UserService';
import FetchClient from '@cb/common-ts/FetchClient';
import useCancelRegistry from '@cb/product-react/Hooks/UseCancelRegistry';

export type SetupMfaModalProps = {
	email: string;
	password: string;
	onMfaSetupComplete?: (mfaCode: string, secret: string) => Promise<void>;
};

export default function SetupMfaModal(props: SetupMfaModalProps) {
	const { email, password, onMfaSetupComplete } = props;
	const [secret, setSecret] = useState<string | undefined>(undefined);
	const [mfaCode, setMfaCode] = useState('');
	const [mfaAccepted, setMfaAccepted] = useState(false);

	const handleMfaSetupCompleted = useCallback(
		async (mfaCode: string, secret: string) => {
			setMfaAccepted(true);
			if (onMfaSetupComplete) {
				await onMfaSetupComplete(mfaCode, secret);
			}
		},
		[onMfaSetupComplete],
	);

	const items: StepperModalContentItem[] = [
		{
			canGoBack: () => false,
			canGoNext: () => true,
			component: () => <StyledStepOne email={email} onSecretAvailable={setSecret} />,
		},
		{
			canGoBack: () => true,
			canGoNext: () => secret !== undefined && mfaCode.length === 6,
			component: () => (
				<StepTwo>
					<p>Enter the code generated by your authenticator app here</p>
					<PinInput length={6} onChange={setMfaCode} />
				</StepTwo>
			),
		},
		{
			canGoBack: () => !mfaAccepted,
			canGoNext: () => mfaAccepted,
			component: () =>
				secret !== undefined && mfaCode ? (
					<StepThree
						email={email}
						password={password}
						secret={secret}
						mfaCode={mfaCode}
						onMfaAccepted={handleMfaSetupCompleted}
					/>
				) : null,
		},
	];

	return <StepperModalContent title="Setup MFA" items={items} width={ModalWidths.MEDIUM} />;
}

export type StepOneProps = {
	email: string;
	onSecretAvailable: (secret: string) => void;
};

function StepOne(props: StepOneProps) {
	const { email, onSecretAvailable } = props;
	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const registerAbort = useCancelRegistry();

	const [secret, setSecret] = useState<string | undefined>(undefined);
	const [totpUri, setTotpUri] = useState<string | undefined>(undefined);

	const requestMfaSecret = useCallback(async () => {
		const loader = addLoader();
		try {
			const request = FetchClient.autoAbort(UserService.RequestMfaSecret(email), registerAbort);
			const secret = await request.response;
			setSecret(secret.secret);
			setTotpUri(secret.uri);
			onSecretAvailable(secret.secret);
		} finally {
			removeLoader(loader);
		}
	}, [addLoader, email, onSecretAvailable, registerAbort, removeLoader]);

	useEffect(() => {
		requestMfaSecret().catch((err) => {
			throw err;
		});
	}, [requestMfaSecret]);

	const qrCodeHtml = useMemo(() => {
		if (totpUri) {
			const generator = createQrCodeGenerator(0, 'L');
			generator.addData(totpUri);
			generator.make();
			return generator.createSvgTag(4, 16);
		} else {
			return null;
		}
	}, [totpUri]);

	return (
		<div>
			<p>Complete the following steps to enable MFA on your Codebots account:</p>
			<ol>
				<li>Install a TOTP authenticator app of your choice.</li>
				<li>Scan the QR code below with your authenticator app, then click Next.</li>
			</ol>
			{isLoading ? <LoadingSpinner /> : null}
			{qrCodeHtml && <QRCode dangerouslySetInnerHTML={{ __html: qrCodeHtml }} />}

			<p>Alternatively, you can manually enter the following code into your authenticator app:</p>
			<Codeblock text={secret} />

			<p>Once your authenticator app is set up, continue on to the next step.</p>
		</div>
	);
}

export type StepThreeProps = {
	email: string;
	password: string;
	secret: string;
	mfaCode: string;
	onMfaAccepted?: (mfaCode: string, secret: string) => Promise<void>;
	onMfaRejected?: () => Promise<void>;
};

function StepThree(props: StepThreeProps) {
	const { email, password, secret, mfaCode, onMfaAccepted, onMfaRejected } = props;
	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const [mfaSetupCompleted, setMfaSetupCompleted] = useState(false);

	const promise = useRef<Promise<unknown> | null>(null);

	const submitMfaCode = useCallback(async () => {
		if (promise.current) {
			return;
		}
		const loader = addLoader();

		try {
			promise.current = UserService.SetupMfa({ email, password, secret, mfaCode });

			await promise.current;

			setMfaSetupCompleted(true);

			if (onMfaAccepted) {
				await onMfaAccepted(mfaCode, secret);
			}
		} catch (err) {
			setMfaSetupCompleted(false);
			if (onMfaRejected) {
				await onMfaRejected();
			}
		} finally {
			removeLoader(loader);
		}
	}, [addLoader, email, mfaCode, onMfaAccepted, onMfaRejected, password, removeLoader, secret]);

	useEffect(() => {
		submitMfaCode().catch((err) => {
			throw err;
		});
	}, [submitMfaCode]);

	return (
		<div>
			{isLoading ? (
				<LoadingSpinner />
			) : mfaSetupCompleted ? (
				<p>MFA setup complete. Please wait...</p>
			) : (
				<>
					<h3>Oh dear...</h3>
					<p>
						Look's like that MFA token is incorrect. Please try again, or check your Authenticator
						configuration.
					</p>
				</>
			)}
		</div>
	);
}

const QRCode = styled.div`
	display: flex;
	justify-content: center;
	margin: 24px 0;
`;

const StyledStepOne = styled(StepOne)`
	${({ theme }) => css`
		li {
			margin: ${theme.spacing.sm} 0;
		}
	`}
`;

const StepTwo = styled.div`
	${({ theme }) => css`
		.PinInput {
			display: flex;
			justify-content: center;
			margin-top: ${theme.spacing.xl};
		}
	`}
`;
