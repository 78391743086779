import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Form from '../../Components/Abstract/Form';
import ForgotStartRequest from '../../Models/Request/ForgotStartRequest';
import useModelState from '../../Hooks/UseModelState';
import AuthService from '../../Services/AuthService';
import HttpErrorHandler from '../../Utils/HttpErrorHandler';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import Input from '@cb/solaris-react/Components/Input/Input';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Size, Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';

export default function ForgotStart() {
	const history = useHistory();
	const { applyValidation, validateAll } = useValidation<ForgotStartRequest>();

	const { model, setModelAttribute } = useModelState<ForgotStartRequest>({});

	const [isLoading, setIsLoading] = useState(false);
	const [isRequestSent, setIsRequestSent] = useState(false);

	const onBackClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		history.goBack();
		return false;
	};

	const onForgotSubmitted: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (validateAll(model)) {
			setIsLoading(true);

			await HttpErrorHandler.tryAsync(async () => {
				await AuthService.RequestResetPassword(model);
				setIsRequestSent(true);
			});

			setIsLoading(false);
		} else {
			QuickToast.error('Please check your form for validation issues.');
		}

		return false;
	};

	if (isRequestSent) {
		return (
			<Form>
				<p>
					Thank you. If your email address matched our records, you will be sent an email containing a link to
					reset your password. Please check your inbox for further instructions.
				</p>
				<Button size={Size.large} variant={Variant.outline} onClick={onBackClicked}>
					Back to login
				</Button>
			</Form>
		);
	}

	return (
		<Form action="#" method="post" onSubmit={onForgotSubmitted}>
			{isLoading && <LoadingSpinner fullPageOverlay />}
			<Input
				label="Your email"
				type="email"
				size={Size.large}
				onChange={(val) => setModelAttribute('email', val)}
				validation={applyValidation('email', {
					required: true,
					isEmail: true,
				})}
			/>
			<ButtonGroup>
				<Button size={Size.large} variant={Variant.outline} onClick={onBackClicked}>
					Back to login
				</Button>
				<Button size={Size.large} variant={Variant.solid} scheme={Scheme.success} type={'submit'}>
					Send email
				</Button>
			</ButtonGroup>
		</Form>
	);
}
