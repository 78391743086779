import { RuleConfigs } from '@cb/solaris-react/Hooks/Validation/ValidationRules';

export default interface SignupRequest {
	fullName?: string;
	email?: string;
	password?: string;
	role?: string;
	companyType?: string;
	acceptTermsAndConditions?: boolean;
}

export const SignupRequestValidation: {
	[key in keyof SignupRequest]: Partial<RuleConfigs>;
} = {
	fullName: {
		required: true,
	},
	email: {
		required: true,
		isEmail: true,
	},
	password: {
		required: true,
		isLength: {
			value: true,
			options: {
				min: 12,
			},
			message: 'Field must be at least 12 characters long',
		},
	},
	role: {
		required: true,
	},
	companyType: {
		required: true,
	},
	acceptTermsAndConditions: {
		equals: {
			value: 'true',
			message: 'Please accept the terms and conditions',
		},
	},
};
