import React from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import styled from 'styled-components';
import useQueryParameter from '@cb/product-react/Hooks/UseQueryParameter';
import { ExternalLinks } from '../../Utils/NavigationUtils';
import { useHistory } from 'react-router';
import { GlobalRoutes } from '@cb/product-react/Utils/NavigationUtils';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';
import { Variant, Scheme, Size } from '@cb/solaris-react/Constants/System';
import useConfiguration from '@cb/product-react/Hooks/UseConfiguration';

export default function ErrorPage() {
	const history = useHistory();
	const errorType = useQueryParameter('error');
	const errorDescription = useQueryParameter('error_description');
	const errorHint = useQueryParameter('error_hint');
	const config = useConfiguration();

	const onLogoutClicked = () => {
		history.replace(GlobalRoutes.Logout);
	};

	const onDocumentationClicked = () => {
		window.open(config.productConfig.docs, '_blank');
	};
	const onMattermostClicked = () => {
		window.open(config.productConfig.mattermost, '_blank');
	};

	const onEmailClicked = () => {
		window.open(ExternalLinks.SupportEmail, '_blank');
	};

	return (
		<CenteredPage pageTitle="Error - Codebots" hideSideMenu title="An error has occurred." subtitle="Oh dear!">
			{errorType ? <ErrorType>{errorType}</ErrorType> : null}
			{errorDescription ? <ErrorDescription>{errorDescription}</ErrorDescription> : null}
			{errorHint ? <ErrorDescription>{errorHint}</ErrorDescription> : null}
			<p>
				We suggest you try signing out and in again. If you continue to have further issues, please post on
				Mattermost or get in touch with support directly.
			</p>
			<ButtonGroup>
				<Button variant={Variant.solid} scheme={Scheme.secondary} size={Size.large} onClick={onLogoutClicked}>
					Logout
				</Button>
				<Button
					variant={Variant.solid}
					scheme={Scheme.brand1}
					size={Size.large}
					onClick={onDocumentationClicked}
				>
					Read our documentation
				</Button>
				<Button variant={Variant.solid} scheme={Scheme.brand1} size={Size.large} onClick={onMattermostClicked}>
					Visit Mattermost
				</Button>
				<Button variant={Variant.solid} scheme={Scheme.brand2} size={Size.large} onClick={onEmailClicked}>
					Email support
				</Button>
			</ButtonGroup>
		</CenteredPage>
	);
}

const ErrorType = styled.p`
	font-size: ${(props) => props.theme.typography.lg};
	color: ${(props) => props.theme.palette.error};
`;
const ErrorDescription = styled.p`
	color: ${(props) => props.theme.palette.error};
`;
