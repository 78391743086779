import ForgotStartRequest from '../Models/Request/ForgotStartRequest';
import ForgotCompleteRequest from '../Models/Request/ForgotCompleteRequest';
import LoginRequest from '../Models/Request/LoginRequest';
import ConfirmEmailRequest from '../Models/Request/ConfirmEmailRequest';
import { ApiEndpoints } from '../Utils/NavigationUtils';
import SecureRandomString from '@cb/common-ts/SecureRandomString';
import User from '../Models/User';
import FetchClient, { FetchClientError, Problem } from '@cb/common-ts/FetchClient';

export enum LoginResponseError {
	MfaRequiredException = 'MfaRequiredException',
	InvalidCredentialsException = 'InvalidCredentialsException',
	MfaSetupRequiredException = 'MfaSetupRequiredException',
	MfaInvalidException = 'MfaInvalidException',
}

export default class AuthService {
	private static readonly _fetchClient = new FetchClient();

	public static GenerateSecurePassword(length = 16) {
		return SecureRandomString.generateAlphanumeric(length);
	}

	public static Logout() {
		window.location.href = ApiEndpoints.Logout;
	}

	public static async Login(loginRequest: LoginRequest): Promise<LoginResponseError | User> {
		try {
			const request = this._fetchClient.postJson<User, LoginRequest>(ApiEndpoints.Login, loginRequest);
			const response = await request.response;
			return response;
		} catch (err) {
			// If the status is a 401 then we are unauthorised, so we return false
			if (err instanceof Problem && err.status === 401) {
				return Promise.resolve(LoginResponseError[err.type as keyof typeof LoginResponseError]);
			}
			throw err;
		}
	}

	public static async RequestResetPassword(resetRequest: ForgotStartRequest) {
		const { response } = this._fetchClient.post(ApiEndpoints.ResetPasswordRequest, JSON.stringify(resetRequest), {
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				});
		await response;
	}

	public static async CompleteResetPassword(completeRequest: ForgotCompleteRequest) {
		const { response } = this._fetchClient.post(ApiEndpoints.ResetPasswordComplete, JSON.stringify(completeRequest), {
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				});
		await response;
	}

	public static async ConfirmEmailAddress(request: ConfirmEmailRequest): Promise<boolean> {
		try {
			const { response } = this._fetchClient.postJson(ApiEndpoints.ConfirmEmail, request);
			await response;
			return true;
		} catch (err) {
			// Rethrow the error if it is not a 404
			if (err instanceof FetchClientError && err.status === 404) {
				return false;
			}

			throw err;
		}
	}
}
