export enum AuthRoutes {
	Login = '/login',
	Signup = '/signup',
	Profile = '/profile',
	Forgot = '/forgot',
	Confirm = '/confirm',
	Error = '/error',
}

export enum ApiEndpoints {
	Profile = '/user/me',
	UpdatePassword = '/user/me/password',
	SetupMfa = '/user/me/mfa/setup',
	DeleteMfa = '/user/me/mfa/delete',
	RequestMfaSecret = '/user/me/mfa/secret',

	ConfirmEmail = '/user/email/confirm',

	ResetPasswordRequest = '/auth/reset/request',
	ResetPasswordComplete = '/auth/reset/complete',

	Signup = '/auth/signup',
	Login = '/auth/login',
	Logout = '/oauth2/logout',
}

export enum ExternalLinks {
	SupportEmail = 'mailto:support@codebots.com',
	PrivacyPolicy = 'https://codebots.com/privacy',
	SoftwareLicence = 'https://codebots.com/full-software-licence',
}
