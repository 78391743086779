import React from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';

export default function NotFoundPage() {
	return (
		<CenteredPage pageTitle="Not Found - Codebots" hideSideMenu title="An error has occurred." subtitle="Not found">
			<p>Sorry, the page you requested could not be found.</p>
		</CenteredPage>
	);
}
