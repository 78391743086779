import styled from 'styled-components';

export default styled.form`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	align-self: center;
	max-width: ${(props) => props.theme.breakpoints.xxxs};
`;
