/* eslint-disable react/no-unescaped-entities */
import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import React, { useMemo, useState } from 'react';
import UserService from '../../Services/UserService';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import { FetchClientError } from '@cb/common-ts/FetchClient';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import { GlobalRoutes } from '@cb/product-react/Utils/NavigationUtils';

export default function DeleteMfaModal() {
	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const { applyValidation, isValid, validateField } = useValidation<{ password: string }>();

	const [password, setPassword] = useState('');

	const actions: ModalAction[] = useMemo(
		() => [
			{
				variant: Variant.quiet,
				text: 'Cancel',
				callback: () => {
					ModalManager.hideModal();
				},
			},
			{
				callback: async () => {
					if (!validateField('password', password)) {
						return;
					}
					const loader = addLoader();
					try {
						await UserService.DeleteMfa(password);
						removeLoader(loader);
						ModalManager.hideModal();
						QuickToast.success(`Successfully deleted MFA. Please wait...`);
						setTimeout(() => {
							window.location.href = GlobalRoutes.Logout;
						}, 0);
						window.location.href = GlobalRoutes.Logout;
					} catch (err) {
						if (err instanceof FetchClientError && err.status === 401) {
							QuickToast.error(`The provided password is not correct`);
						} else {
							QuickToast.error(`Failed to delete MFA: ${err}`);
						}
						removeLoader(loader);
					}
				},
				scheme: Scheme.error,
				text: 'Delete',
				disabled: !isValid || isLoading,
			},
		],
		[addLoader, isLoading, isValid, password, removeLoader, validateField],
	);

	return (
		<ModalContent
			title="Delete MFA"
			actions={actions}
			body={
				<div>
					<p>
						Deleting your MFA credentials will log you out and force you to setup new MFA credentials when
						you log in again.
					</p>
					<p>If you're sure you want to continue, confirm your current password then click "Delete"</p>
					<PasswordInput
						label="Current password"
						value={password}
						onChange={setPassword}
						validation={applyValidation('password', {
							required: true,
						})}
					/>
				</div>
			}
		/>
	);
}
