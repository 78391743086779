import React from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
// import { useHistory } from 'react-router-dom';
// import Form from '../../Components/Abstract/Form';
// import styled from 'styled-components';
// import SignupRequest, { SignupRequestValidation } from '../../Models/Request/SignupRequest';
// import { ExternalLinks } from '../../Utils/NavigationUtils';
// import useQueryParameter from '@cb/product-react/Hooks/UseQueryParameter';
// import AuthService from '../../Services/AuthService';
// import HttpErrorHandler from '../../Utils/HttpErrorHandler';
// import { QuickToast } from '@cb/product-react/Utils/QuickToast';
// import Input, { AutoComplete } from '@cb/solaris-react/Components/Input/Input';
// import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
// import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
// import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';
// import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
// import { Size, Variant, Scheme } from '@cb/solaris-react/Constants/System';
// import useValidation from '@cb/solaris-react/Hooks/UseValidation';
// import useModelState from '../../Hooks/UseModelState';
// import Select, { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
// import Checkbox from '@cb/solaris-react/Components/Input/Checkbox';

export default function SignupPage() {
	// const history = useHistory();
	// const { applyValidation, validateAll } = useValidation<SignupRequest>();

	// const [isLoading, setIsLoading] = useState(false);

	// const redirect = useQueryParameter('redirect');
	// const email = useQueryParameter('email');
	// const invitation = useQueryParameter('invitation');
	// const refCode = useQueryParameter('r');

	// const { model, setModelAttribute } = useModelState<SignupRequest>({
	// 	email: email,
	// });

	// const onBackClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
	// 	e.preventDefault();
	// 	history.goBack();
	// 	return false;
	// };

	// const onSignupSubmitted: React.FormEventHandler<HTMLFormElement> = async (e) => {
	// 	e.preventDefault();

	// 	if (validateAll(model)) {
	// 		setIsLoading(true);
	// 		await HttpErrorHandler.tryAsync(async () => {
	// 			const redirectUri = await AuthService.Signup(model, redirect, refCode);
	// 			QuickToast.success('Signup successful, redirecting you now...');
	// 			window.location.href = redirectUri;
	// 		});
	// 		setIsLoading(false);
	// 	} else {
	// 		QuickToast.error('Please check your form for validation issues.');
	// 	}

	// 	return false;
	// };

	// const onCopyToClipboard = (success: boolean) => {
	// 	if (success) {
	// 		QuickToast.success('Copied password to clipboard');
	// 	} else {
	// 		QuickToast.error('Failed to copy to clipboard.');
	// 	}
	// };

	return (
		<CenteredPage pageTitle="Signup to Codebots" hideSideMenu title="Codebots." subtitle="Sign up">
			<p>Sorry, we are not currently accepting any new signup.</p>
			{/* {isLoading && <LoadingSpinner fullPageOverlay />}
			<LoginForm action="#" method="post" onSubmit={onSignupSubmitted}>
				{invitation ? (
					<>
						<p>{invitation} has invited you to collaborate!</p>
						<p>Please fill out your details to create your Codebots account.</p>
					</>
				) : (
					<p>Welcome to Codebots. Please add your details below to create an account.</p>
				)}

				<Input
					label="Full name"
					type="text"
					size={Size.large}
					onChange={(val) => setModelAttribute('fullName', val)}
					validation={applyValidation('fullName', SignupRequestValidation.fullName!)}
					autocomplete={AutoComplete.name}
				/>
				<Input
					label="Email address"
					type="email"
					size={Size.large}
					initialValue={model.email}
					onChange={(val) => setModelAttribute('email', val)}
					validation={applyValidation('email', SignupRequestValidation.email!)}
					autocomplete={AutoComplete.email}
				/>
				<PasswordInput
					label="Password"
					size={Size.large}
					onChange={(val) => setModelAttribute('password', val)}
					validation={applyValidation('password', SignupRequestValidation.password!)}
					isCopyPasswordVisible
					isGeneratePasswordVisible
					isShowPasswordVisible
					onCopyToClipboard={onCopyToClipboard}
				/>
				<Select
					label="Your role"
					size={Size.large}
					options={RoleOptions}
					emptyOption={NoSelectionOption}
					update={(val) => setModelAttribute('role', val.value)}
					validation={applyValidation('role', SignupRequestValidation.role!)}
				/>
				<Select
					label="Company type"
					size={Size.large}
					options={CompanyTypeOptions}
					currentOption={NoSelectionOption}
					update={(val) => setModelAttribute('companyType', val.value)}
					validation={applyValidation('companyType', SignupRequestValidation.companyType!)}
				/>
				<Checkbox
					label={<TermsText />}
					value={model.acceptTermsAndConditions ? true : false}
					onChange={(val) => setModelAttribute('acceptTermsAndConditions', val)}
					size={Size.large}
					validation={applyValidation(
						'acceptTermsAndConditions',
						SignupRequestValidation.acceptTermsAndConditions!,
					)}
				/>
				<ButtonGroup>
					<Button size={Size.large} variant={Variant.outline} onClick={onBackClicked}>
						Back to login
					</Button>
					<Button size={Size.large} variant={Variant.solid} scheme={Scheme.success} type={'submit'}>
						Sign up
					</Button>
				</ButtonGroup>
			</LoginForm> */}
		</CenteredPage>
	);
}

// const TermsText = () => {
// 	return (
// 		<SmallText>
// 			I agree that my use of the Codebots platform is subject to my acceptance and consent of the Codebots{' '}
// 			<Link target="_blank" href={ExternalLinks.PrivacyPolicy}>
// 				privacy policy
// 			</Link>{' '}
// 			and{' '}
// 			<Link target="_blank" href={ExternalLinks.SoftwareLicence}>
// 				Software Licence.
// 			</Link>
// 		</SmallText>
// 	);
// };

// const RoleOptions = ['Developer', 'CEO/Founder', 'Manager', 'Other'].map<SelectOption<string>>((option) => ({
// 	key: option,
// 	value: option,
// 	display: option,
// }));

// const CompanyTypeOptions = [
// 	'Software development',
// 	'Marketing agency',
// 	'In-house development',
// 	'Freelancer',
// 	'Other',
// ].map<SelectOption<string>>((option) => ({
// 	key: option,
// 	value: option,
// 	display: option,
// }));

// const NoSelectionOption: SelectOption<string> = {
// 	display: '--- Please select ---',
// 	key: '',
// 	value: '',
// };

// const Link = styled.a`
// 	color: ${(props) => props.theme.palette.shade1};
// `;

// const SmallText = styled.p`
// 	margin: 0;
// 	padding: 0;
// 	font-size: ${(props) => props.theme.typography.sm};
// `;

// const LoginForm = styled(Form)``;
