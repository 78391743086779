import React from 'react';
import AppSkeleton, { RouteDefinition } from '@cb/product-react/Components/AppSkeleton';
import { AuthRoutes } from '../Utils/NavigationUtils';
import { GlobalRoutes } from '@cb/product-react/Utils/NavigationUtils';
import HomePage from './Home/HomePage';
import LoginPage from './Login/LoginPage';
import SignupPage from './Signup/SignupPage';
import ProfilePage from './User/ProfilePage';
import LogoutPage from './Login/LogoutPage';
import ForgotPage from './Login/ForgotPage';
import ConfirmPage from './Signup/ConfirmPage';
import ErrorPage from './Error/ErrorPage';
import NotFoundPage from './Error/NotFoundPage';
import useUserInfoContext, { UserInfoProvider } from '../Hooks/UseUserInfoContext';
import { fetchAndParseConfiguration } from '@cb/product-react/Config/Configuration';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';

const unauthenticatedRoutes: RouteDefinition[] = [
	{
		path: GlobalRoutes.Home,
		exact: true,
		component: <HomePage />,
	},
	{
		path: AuthRoutes.Login,
		component: <LoginPage />,
	},
	{
		path: AuthRoutes.Forgot,
		component: <ForgotPage />,
	},
	{
		path: AuthRoutes.Signup,
		component: <SignupPage />,
	},
	{
		path: AuthRoutes.Confirm,
		component: <ConfirmPage />,
	},
	{
		path: GlobalRoutes.Logout,
		component: <LogoutPage />,
	},
	{
		path: AuthRoutes.Error,
		component: <ErrorPage />,
	},
	{
		path: AuthRoutes.Profile,
		component: <ProfilePage />,
	},
];

const Auth = () => {
	return (
		<UserInfoProvider>
			<AppSkeleton
				unauthenticatedRoutes={unauthenticatedRoutes}
				defaultPageTitle="Codebots"
				fetchAndParseConfiguration={fetchAndParseConfiguration}
				notFoundComponent={NotFoundPage}
				defaultToLightTheme
			>
				<LoadingState />
			</AppSkeleton>
		</UserInfoProvider>
	);
};

function LoadingState() {
	const userInfo = useUserInfoContext();
	return userInfo.isLoading ? <LoadingSpinner fullPageOverlay /> : null;
}

export default Auth;
