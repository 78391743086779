import React from 'react';
import styled from 'styled-components';
import logoWhite from '../../Static/img/logo-white.svg';
import logoColoured from '../../Static/img/logo-coloured.svg';
import { BinaryTheme } from '@cb/product-react/Stores/ThemeStore';
import { ExternalLinks } from '../../Utils/NavigationUtils';
import { useTheme } from '@cb/product-react/Hooks/UseThemeStore';

export default function Footer() {
	const { themeType, isThemeLoading } = useTheme();

	if (isThemeLoading) {
		return null;
	}

	const logoSrc = themeType == BinaryTheme.DARK ? logoWhite : logoColoured;

	return (
		<StyledFooter>
			<Logo src={logoSrc} alt="Codebots" />
			<SmallText>
				<Link target="_blank" href={ExternalLinks.PrivacyPolicy}>
					Privacy Policy
				</Link>
				|
				<Link target="_blank" href={ExternalLinks.SoftwareLicence}>
					Software Licence
				</Link>
			</SmallText>
			<SmallText>Copyright &copy; {new Date().getFullYear()} Codebots Pty Ltd</SmallText>
		</StyledFooter>
	);
}

const StyledFooter = styled.div`
	text-align: center;
`;
const Logo = styled.img`
	width: 100%;
	max-width: ${(props) => props.theme.sizing.xl};
`;
const SmallText = styled.p`
	font-size: ${(props) => props.theme.typography.sm};
`;
const Link = styled.a`
	color: ${(props) => props.theme.palette.shade1};
	margin: 0 ${(props) => props.theme.spacing.sm};
`;
