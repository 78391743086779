import React, { useCallback, useEffect, useState } from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import { useHistory } from 'react-router';
import { AuthRoutes } from '../../Utils/NavigationUtils';
import styled from 'styled-components';
import Form from '../../Components/Abstract/Form';
import LoginRequest from '../../Models/Request/LoginRequest';
import useModelState from '../../Hooks/UseModelState';
import useQueryParameter from '@cb/product-react/Hooks/UseQueryParameter';
import AuthService, { LoginResponseError } from '../../Services/AuthService';
import { GlobalRoutes } from '@cb/product-react/Utils/NavigationUtils';
import Input, { AutoComplete } from '@cb/solaris-react/Components/Input/Input';
import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Size, Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import useUserInfoContext from '../../Hooks/UseUserInfoContext';
import ExternalRedirect from '@cb/solaris-react/Components/Navigation/ExternalRedirect';
import Link from '@cb/solaris-react/Components/Navigation/Link';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import ValidateMfaModal from '../User/ValidateMfaModal';
import SetupMfaModal from '../User/SetupMfaModal';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';

export default function LoginPage() {
	const history = useHistory();
	const userContext = useUserInfoContext();
	const { applyValidation, validateAll } = useValidation<LoginRequest>();

	const returnUrl = useQueryParameter('return_url');
	const clientName = useQueryParameter('client_name');
	const email = useQueryParameter('email');

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const { model, setModelAttribute } = useModelState<LoginRequest>({
		email: email,
	});

	const [redirectUri, setRedirectUri] = useState<string>();

	useEffect(() => {
		if (!returnUrl) {
			setTimeout(() => {
				history.replace(GlobalRoutes.Home);
			}, 0);
		} else if (userContext.user && returnUrl) {
			window.location.href = returnUrl;
		}
	}, [history, returnUrl, userContext.user]);

	useEffect(() => {
		if (userContext.isLoading) {
			addLoader('UserContext');
		} else {
			removeLoader('UserContext');
		}
	}, [addLoader, removeLoader, userContext.isLoading]);

	const requestLogin = useCallback(
		async (mfaCode?: string): Promise<boolean> => {
			if (validateAll(model)) {
				const loader = addLoader();
				try {
					const loginResponse = await AuthService.Login({
						email: model.email,
						password: model.password,
						mfaCode: mfaCode && mfaCode.length === 6 ? mfaCode : undefined,
					});

					if (
						typeof loginResponse === 'object' &&
						'id' in loginResponse &&
						loginResponse.id &&
						loginResponse.email
					) {
						setRedirectUri(returnUrl);
						QuickToast.success('Login successful, redirecting you now...');
						return true;
					} else if (loginResponse === LoginResponseError.MfaRequiredException) {
						ModalManager.createAndShowModal({
							content: <ValidateMfaModal onSubmitRequested={(code) => requestLogin(code)} />,
							allowWrapperClickToClose: false,
						});
						return false;
					} else if (loginResponse === LoginResponseError.MfaSetupRequiredException) {
						ModalManager.createAndShowModal({
							content: (
								<SetupMfaModal
									email={model.email ?? ''}
									password={model.password ?? ''}
									onMfaSetupComplete={async (mfaCode) => {
										await requestLogin(mfaCode);
									}}
								/>
							),
							allowWrapperClickToClose: false,
						});
						return false;
					} else if (loginResponse === LoginResponseError.MfaInvalidException) {
						QuickToast.error('Invalid MFA code. Please try again.');
						return false;
					} else if (loginResponse === LoginResponseError.InvalidCredentialsException) {
						QuickToast.error('Invalid email and/or password.');
						return false;
					}
				} catch (err) {
					ErrorUtils.handleError(err);
				} finally {
					removeLoader(loader);
				}
			} else {
				QuickToast.error('Please check your form for validation issues.');
			}

			return false;
		},
		[addLoader, model, removeLoader, returnUrl, validateAll],
	);

	if (!returnUrl) {
		return null;
	}

	const onForgotClicked: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		history.push(AuthRoutes.Forgot);
		return false;
	};

	const onLoginSubmitted: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		requestLogin().catch((err) => {
			throw err;
		});
		return false;
	};

	const oauthClientName = clientName ?? 'Codebots';

	return (
		<CenteredPage
			pageTitle={`Login to ${oauthClientName}`}
			hideSideMenu
			title={`${oauthClientName}.`}
			subtitle="Sign in"
		>
			{(isLoading || redirectUri) && <LoadingSpinner fullPageOverlay />}
			{redirectUri && <ExternalRedirect to={redirectUri} />}
			<LoginForm action="#" method="post" onSubmit={onLoginSubmitted}>
				<Input
					label="Your email"
					type="email"
					size={Size.large}
					initialValue={email}
					onChange={(val) => setModelAttribute('email', val)}
					validation={applyValidation('email', {
						required: true,
						isEmail: true,
					})}
					autocomplete={AutoComplete.email}
				/>
				<PasswordInput
					label="Your password"
					size={Size.large}
					onChange={(val) => setModelAttribute('password', val)}
					validation={applyValidation('password', {
						required: true,
					})}
					isCopyPasswordVisible={false}
					isGeneratePasswordVisible={false}
					isShowPasswordVisible
				/>
				<Button size={Size.large} variant={Variant.solid} scheme={Scheme.success} type={'submit'}>
					Sign in
				</Button>
				<Link size={Size.medium} href="/forgot" onClick={onForgotClicked}>
					Forgotten your details?
				</Link>
			</LoginForm>
		</CenteredPage>
	);
}

const LoginForm = styled(Form)`
	> button {
		width: 100%;
		margin: ${(props) => props.theme.spacing.xl} 0;
	}
`;
