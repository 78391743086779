import React from 'react';
import Page, { PageProps } from '@cb/product-react/Components/Layout/Page';
import styled from 'styled-components';
import Footer from './Footer';

export interface CenteredPageProps extends PageProps {
	title?: string;
	subtitle?: string;
}

export default function CenteredPage(props: CenteredPageProps) {
	return (
		<Page {...props}>
			<Wrapper>
				<Column>
					{props.title ? <Title>{props.title}</Title> : null}
					{props.subtitle ? <Subtitle>{props.subtitle}</Subtitle> : null}
					{props.children}
				</Column>
				<Footer />
			</Wrapper>
		</Page>
	);
}

const Wrapper = styled.section`
	min-height: 100%;
	padding: ${(props) => props.theme.spacing.xl};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const Column = styled.section`
	flex-grow: 1;
	width: 100%;
	max-width: ${(props) => props.theme.breakpoints.md};
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Title = styled.div`
	font-size: ${(props) => props.theme.typography.xl};
	margin: 0 0 ${(props) => props.theme.spacing.md} 0;
`;
const Subtitle = styled.div`
	font-size: calc(${(props) => props.theme.typography.xl} * 3);
	margin: 0 0 ${(props) => props.theme.spacing.xl} 0;
	font-weight: ${(props) => props.theme.typography.FontWeight.bold};
`;
