import React, { useState } from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import styled from 'styled-components';
import { AuthRoutes } from '../../Utils/NavigationUtils';
import useUserInfoContext from '../../Hooks/UseUserInfoContext';
import Icon from '@cb/solaris-react/Components/Content/Icon';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { AnimationTimings } from '@cb/solaris-react/Constants/Animation';
import { Variant, Scheme, Size } from '@cb/solaris-react/Constants/System';
import useConfiguration from '@cb/product-react/Hooks/UseConfiguration';

export default function HomePage() {
	const [isRedirecting, setIsRedirecting] = useState(false);
	const user = useUserInfoContext();
	const config = useConfiguration();

	const redirect = (url: string) => {
		setIsRedirecting(true);
		setTimeout(() => {
			window.location.href = url;
		}, 0);
	};

	return (
		<CenteredPage pageTitle="Welcome to Codebots" hideSideMenu title="Welcome." subtitle="Where to?">
			{isRedirecting && <LoadingSpinner fullPageOverlay />}
			<ButtonGroup>
				{/* <Button
					variant={Variant.solid}
					scheme={Scheme.brand1}
					size={Size.large}
					title="Studio"
					onClick={() => redirect(config.productConfig.studio)}
				>
					<Icon name="model" className="icon" />
					Studio
				</Button> */}
				<Button
					variant={Variant.solid}
					scheme={Scheme.brand2}
					size={Size.large}
					title="Platform"
					onClick={() => redirect(config.productConfig.platform)}
				>
					<Icon name="tests" className="icon" />
					Platform
				</Button>
				<Button
					variant={Variant.solid}
					scheme={Scheme.accent1}
					size={Size.large}
					title="Documentation"
					onClick={() => redirect(config.productConfig.docs)}
				>
					<Icon name="book" className="icon" />
					Documentation
				</Button>
				<Button
					variant={Variant.solid}
					scheme={Scheme.accent2}
					size={Size.large}
					title="Mattermost"
					onClick={() => redirect(config.productConfig.mattermost)}
				>
					<Icon name="chats" className="icon" />
					Mattermost
				</Button>
				{user.user && (
					<Button
						variant={Variant.solid}
						scheme={Scheme.success}
						size={Size.large}
						title="My profile"
						onClick={() => redirect(AuthRoutes.Profile)}
					>
						<Icon name="person" className="icon" />
						My profile
					</Button>
				)}
			</ButtonGroup>
		</CenteredPage>
	);
}

const ButtonGroup = styled.div`
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	.icon {
		font-size: ${(props) => props.theme.typography.xl};
		margin-bottom: ${(props) => props.theme.spacing.md};
	}

	& > button {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&[title='App Studio'] {
			color: white;
		}

		flex-grow: 0;
		flex-shrink: 0;
		transform: scale(1);

		width: ${(props) => props.theme.sizing.xl};
		height: ${(props) => props.theme.sizing.xl};
		margin: ${(props) => props.theme.spacing.md};
		font-size: ${(props) => props.theme.typography.lg};
		transition: all 200ms ${AnimationTimings.productive};

		&:hover {
			transform: scale(1.05);
		}
	}
`;
