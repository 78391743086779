import { FetchClientError } from '@cb/common-ts/FetchClient';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';

const DEFAULT_ERROR_MESSAGE = 'An unexpected error has occurred. Please contact support.';

export default class HttpErrorHandler {
	public static try(func: () => void, throwErr = false): void {
		try {
			func();
		} catch (err) {
			if (err instanceof FetchClientError) {
				QuickToast.error(err.message);
			} else {
				QuickToast.error(`${DEFAULT_ERROR_MESSAGE} ${(err as Error).message}`);
			}
			if (throwErr) {
				throw err;
			}
		}
	}

	public static async tryAsync<T>(func: () => Promise<T>, throwErr = false): Promise<T | undefined> {
		try {
			return await func();
		} catch (err) {
			if (err instanceof FetchClientError) {
				QuickToast.error(err.message);
			} else {
				QuickToast.error(`${DEFAULT_ERROR_MESSAGE} ${(err as Error).message}`);
			}
			if (throwErr) {
				throw err;
			}
		}
	}
}
