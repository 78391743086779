import React from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import useQueryParameter from '@cb/product-react/Hooks/UseQueryParameter';
import useAsync from '@cb/product-react/Hooks/UseAsync';
import AuthService from '../../Services/AuthService';
import HttpErrorHandler from '../../Utils/HttpErrorHandler';
import Form from '../../Components/Abstract/Form';
import { useHistory } from 'react-router';
import { AuthRoutes } from '../../Utils/NavigationUtils';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Size, Variant } from '@cb/solaris-react/Constants/System';

export default function ConfirmPage() {
	const history = useHistory();
	const email = useQueryParameter('email');
	const challenge = useQueryParameter('challenge');

	const { isResolved, isFinished, result } = useAsync(() => {
		return HttpErrorHandler.tryAsync(async () => {
			return await AuthService.ConfirmEmailAddress({
				challenge,
				email,
			});
		}, true);
	});

	const onBackClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		history.replace(AuthRoutes.Login);
		return false;
	};

	let view = null;

	if (isFinished) {
		if (result && isResolved) {
			view = <Form>Success! Thank you for confirming your email address with us.</Form>;
		} else {
			view = (
				<Form>
					<p>Sorry, we could not verify your email address. Your link might be invalid.</p>
					<Button size={Size.large} variant={Variant.outline} onClick={onBackClicked}>
						Back to login
					</Button>
				</Form>
			);
		}
	} else {
		view = <LoadingSpinner fullPageOverlay text="Please wait while we confirm your email address..." />;
	}

	return (
		<CenteredPage pageTitle="Reset password - Codebots" hideSideMenu title="Codebots." subtitle="Confirm email">
			{view}
		</CenteredPage>
	);
}
