import React, { useEffect, useState } from 'react';
import Form from '../../Components/Abstract/Form';
import useUserInfoContext from '../../Hooks/UseUserInfoContext';
import useModelState from '../../Hooks/UseModelState';
import User from '../../Models/User';
import HttpErrorHandler from '../../Utils/HttpErrorHandler';
import UserService from '../../Services/UserService';
import Avatar, { AvatarUploadHandler } from '@cb/solaris-react/Components/Content/Avatar';
import Input, { AutoComplete } from '@cb/solaris-react/Components/Input/Input';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Size, Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';

export default function ProfileDetailsForm() {
	const { applyValidation, validateAll } = useValidation<User>();
	const userContext = useUserInfoContext();

	const [isLoading, setIsLoading] = useState(false);

	const {
		model: userModel,
		setModel: setUserModel,
		setModelAttribute: setUserModelAttribute,
	} = useModelState<User>({});

	useEffect(() => {
		if (userContext.user !== undefined) {
			setUserModel({
				id: userContext.user.id,
				email: userContext.user.email,
				givenName: userContext.user.givenName,
				familyName: userContext.user.familyName,
			});
		}
	}, [userContext.user, setUserModel]);

	const onUserDetailsSubmitted: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (validateAll(userModel)) {
			setIsLoading(true);
			await HttpErrorHandler.tryAsync(async () => {
				const updatedUser = await UserService.SaveUserInfo(userModel);

				userContext.setUser(updatedUser);

				QuickToast.success('Successfully updated profile information.');
			});
			setIsLoading(false);
		} else {
			QuickToast.error('Please check your form for validation issues.');
		}
	};

	const avatarUploadHandler: AvatarUploadHandler = async (file: File) => {
		const updatedUser = await UserService.UploadPicture(file);
		userContext.setUser(updatedUser);
	};

	if (userContext.user === undefined || !userModel.id) {
		return null;
	}

	return (
		<Form action="#" method="post" onSubmit={onUserDetailsSubmitted}>
			{isLoading && <LoadingSpinner fullPageOverlay />}
			<Avatar
				src={userContext.user.picture}
				userId={userContext.user.id}
				name={`${userContext.user.givenName} ${userContext.user.familyName}`}
				description={userContext.user.email}
				showEdit
				uploadHandler={avatarUploadHandler}
			/>
			<Input
				label="Given name"
				type="text"
				size={Size.large}
				initialValue={userModel.givenName}
				onChange={(val) => setUserModelAttribute('givenName', val)}
				validation={applyValidation('givenName', {
					required: true,
				})}
				autocomplete={AutoComplete.givenName}
			/>
			<Input
				label="Family name"
				type="text"
				size={Size.large}
				initialValue={userModel.familyName}
				onChange={(val) => setUserModelAttribute('familyName', val)}
				validation={applyValidation('familyName', {
					required: true,
				})}
				autocomplete={AutoComplete.familyName}
			/>
			<ButtonGroup>
				<Button size={Size.large} variant={Variant.solid} scheme={Scheme.success} type={'submit'}>
					Save changes
				</Button>
			</ButtonGroup>
		</Form>
	);
}
