import React from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import useQueryParameter from '@cb/product-react/Hooks/UseQueryParameter';
import ForgotStart from './ForgotStart';
import ForgotComplete from './ForgotComplete';

export default function ForgotPage() {
	const challenge = useQueryParameter('challenge');
	return (
		<CenteredPage pageTitle="Reset password - Codebots" hideSideMenu title="Codebots." subtitle="Reset password">
			{challenge ? <ForgotComplete /> : <ForgotStart />}
		</CenteredPage>
	);
}
