/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { GlobalRoutes } from '@cb/product-react/Utils/NavigationUtils';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import Form from '../../Components/Abstract/Form';
import useUserInfoContext from '../../Hooks/UseUserInfoContext';
import { useHistory } from 'react-router';
import ProfileDetailsForm from './ProfileDetailsForm';
import ProfilePasswordForm from './ProfilePasswordForm';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import { Size, Variant } from '@cb/solaris-react/Constants/System';
import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';

export default function ProfilePage() {
	const history = useHistory();
	const userContext = useUserInfoContext();

	const onLogoutSubmitted: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		history.replace(GlobalRoutes.Logout);
		return false;
	};

	const onBackClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		history.replace(GlobalRoutes.Home);
		return false;
	};

	let view;

	if (userContext.isLoading) {
		view = <Form>Please wait...</Form>;
	} else if (userContext.user === undefined) {
		view = (
			<Form>
				<p>Sorry, it doesn't look like you're logged in.</p>
				<Button size={Size.large} variant={Variant.outline} onClick={onBackClicked}>
					Back to login
				</Button>
			</Form>
		);
	} else {
		view = (
			<>
				<ProfileDetailsForm />
				<ProfilePasswordForm />
				<Form action="#" method="post" onSubmit={onLogoutSubmitted}>
					<ButtonGroup>
						<Button size={Size.large} variant={Variant.outline} type={'submit'}>
							Logout
						</Button>
					</ButtonGroup>
				</Form>
			</>
		);
	}

	return (
		<CenteredPage pageTitle="Your profile - Codebots" hideSideMenu title="Codebots." subtitle="Profile">
			{view}
		</CenteredPage>
	);
}
