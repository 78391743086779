import Form from '../../Components/Abstract/Form';
import useModelState from '../../Hooks/UseModelState';
import { SignupRequestValidation } from '../../Models/Request/SignupRequest';
import UpdatePasswordRequest from '../../Models/Request/UpdatePasswordRequest';
import React, { useCallback, useRef, useState } from 'react';
import HttpErrorHandler from '../../Utils/HttpErrorHandler';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
import { Scheme, Size, Variant } from '@cb/solaris-react/Constants/System';
import { AutoComplete } from '@cb/solaris-react/Components/Input/Input';
import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import { FetchClientError } from '@cb/common-ts/FetchClient';
import UserService from '../../Services/UserService';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import DeleteMfaModal from './DeleteMfaModal';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import { LoginResponseError } from '../../Services/AuthService';
import ValidateMfaModal from './ValidateMfaModal';

export default function ProfilePasswordForm() {
	const { applyValidation, validateAll } = useValidation<UpdatePasswordRequest>();

	const [isLoading, setIsLoading] = useState(false);

	const { model: passwordModel, setModelAttribute: setPasswordModelAttribute } = useModelState<UpdatePasswordRequest>(
		{},
	);

	const requestUpdatePassword = useCallback(
		async (mfaCode?: string): Promise<boolean> => {
			setIsLoading(true);
			const result = await HttpErrorHandler.tryAsync(async () => {
				try {
					const response = await UserService.UpdatePassword({ ...passwordModel, mfaCode });

					if (
						typeof response === 'object' &&
						'id' in response &&
						response.id &&
						response.email
					) {
						setPasswordModelAttribute('currentPassword', '');
						setPasswordModelAttribute('newPassword', '');
						newPasswordValidation.resetValidation();
						currentPasswordValidation.resetValidation();
						QuickToast.success('Successfully changed your password.');
						ModalManager.hideModal();
						return true;
					} else if (response === LoginResponseError.MfaRequiredException) {
						ModalManager.createAndShowModal({
							content: <ValidateMfaModal onSubmitRequested={(code) => requestUpdatePassword(code)} />,
							allowWrapperClickToClose: false,
						});
						return false;
					} else if (response === LoginResponseError.MfaInvalidException) {
						QuickToast.error('Invalid MFA code. Please try again.');
						return false;
					} else if (response === LoginResponseError.InvalidCredentialsException) {
						QuickToast.error('Failed to update your password. Your current password may not be correct.');
						return false;
					} else {
						QuickToast.error('Failed to update your password. Please try again.');
						return false;
					}
				} catch (err) {
					if (err instanceof FetchClientError && err.status === 401) {
						QuickToast.error('Failed to update your password. Your current password may not be correct.');
						return false;
					} else {
						// Let the HttpErrorHandler handle this
						throw err;
					}
				}
			});
			setIsLoading(false);
			return result ?? false;
		}, [passwordModel, setPasswordModelAttribute]);

	const onPasswordSubmitted: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (validateAll(passwordModel)) {
			await requestUpdatePassword();
		} else {
			QuickToast.error('Please check your form for validation issues.');
		}
	};

	const onCopyToClipboard = (success: boolean) => {
		if (success) {
			QuickToast.success('Copied password to clipboard');
		} else {
			QuickToast.error('Failed to copy to clipboard');
		}
	};

	const handleDeleteMfaClicked = () => {
		ModalManager.createAndShowModal({
			maxWidth: ModalWidths.MEDIUM,
			content: <DeleteMfaModal />,
		});
	};

	const newPasswordValidation = applyValidation('newPassword', SignupRequestValidation.password!);
	const currentPasswordValidation = applyValidation('currentPassword', {
		required: true,
	});

	return (
		<Form action="#" method="post" onSubmit={onPasswordSubmitted}>
			{isLoading && <LoadingSpinner fullPageOverlay />}
			<PasswordInput
				label="Current password"
				size={Size.large}
				value={passwordModel.currentPassword}
				onChange={(val) => setPasswordModelAttribute('currentPassword', val)}
				validation={currentPasswordValidation}
				isCopyPasswordVisible={false}
				isGeneratePasswordVisible={false}
				isShowPasswordVisible
				onCopyToClipboard={onCopyToClipboard}
				autocomplete={AutoComplete.currentPassword}
			/>
			<PasswordInput
				label="New password"
				size={Size.large}
				value={passwordModel.newPassword}
				onChange={(val) => setPasswordModelAttribute('newPassword', val)}
				validation={newPasswordValidation}
				isCopyPasswordVisible
				isGeneratePasswordVisible
				isShowPasswordVisible
				onCopyToClipboard={onCopyToClipboard}
			/>
			<ButtonGroup>
				<Button size={Size.large} variant={Variant.solid} scheme={Scheme.success} type={'submit'}>
					Update password
				</Button>
			</ButtonGroup>
			<ButtonGroup>
				<Button
					size={Size.large}
					variant={Variant.solid}
					scheme={Scheme.error}
					type={'button'}
					onClick={handleDeleteMfaClicked}
				>
					Delete MFA
				</Button>
			</ButtonGroup>
		</Form>
	);
}
