import React, { useEffect } from 'react';
import CenteredPage from '../../Components/Abstract/CenteredPage';
import AuthService from '../../Services/AuthService';
import styled from 'styled-components';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';

export default function LogoutPage() {
	useEffect(() => {
		setTimeout(() => {
			AuthService.Logout();
		}, 0);
	}, []);

	return (
		<CenteredPage pageTitle="Codebots" hideSideMenu title="Please wait." subtitle="Signing you out">
			<StyledLoadingSpinner />
		</CenteredPage>
	);
}

const StyledLoadingSpinner = styled(LoadingSpinner)`
	height: 100%;
	flex-grow: 1;
`;
