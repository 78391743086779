/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ForgotCompleteRequest from '../../Models/Request/ForgotCompleteRequest';
import useModelState from '../../Hooks/UseModelState';
import useQueryParameter from '@cb/product-react/Hooks/UseQueryParameter';
import Form from '../../Components/Abstract/Form';
import styled from 'styled-components';
import AuthService from '../../Services/AuthService';
import { SignupRequestValidation } from '../../Models/Request/SignupRequest';
import { GlobalRoutes } from '@cb/product-react/Utils/NavigationUtils';
import HttpErrorHandler from '../../Utils/HttpErrorHandler';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import Input from '@cb/solaris-react/Components/Input/Input';
import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
import ButtonGroup from '@cb/solaris-react/Components/Interactive/Button/ButtonGroup';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Size, Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';

export default function ForgotComplete() {
	const history = useHistory();
	const { applyValidation, validateAll } = useValidation<ForgotCompleteRequest>();

	const { model, setModelAttribute } = useModelState<ForgotCompleteRequest>({
		email: useQueryParameter('email'),
		challenge: useQueryParameter('challenge'),
		password: '',
	});

	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [isFailure, setIsFailure] = useState(false);

	const onCopyToClipboard = (success: boolean) => {
		if (success) {
			QuickToast.success('Copied password to clipboard');
		} else {
			QuickToast.error('Failed to copy to clipboard');
		}
	};

	const onLoginClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		history.replace(GlobalRoutes.Home);
		return false;
	};

	const onCompleteForgotSubmitted: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (validateAll(model)) {
			setIsLoading(true);
			await HttpErrorHandler.tryAsync(async () => {
				try {
					await AuthService.CompleteResetPassword(model);
					setIsComplete(true);
				} catch (err) {
					setIsFailure(true);
				}
			});
			setIsLoading(false);
		} else {
			QuickToast.error('Please check your form for validation issues.');
		}

		return false;
	};

	const handlePasswordChange = (value: string) => {
		setModelAttribute('password', value);
	};

	if (isComplete || isFailure) {
		return (
			<ForgotForm>
				<p>
					{isComplete
						? 'Your password has successfully been reset!'
						: 'Oh dear, it looks like this password reset link is invalid, or has expired.'}
				</p>
				<Button
					size={Size.large}
					variant={isComplete ? Variant.solid : Variant.outline}
					scheme={isComplete ? Scheme.success : undefined}
					onClick={onLoginClicked}
				>
					{isComplete ? 'Continue to login' : 'Back to login'}
				</Button>
			</ForgotForm>
		);
	}

	return (
		<ForgotForm action="#" method="post" onSubmit={onCompleteForgotSubmitted}>
			{isLoading && <LoadingSpinner fullPageOverlay />}
			<Input label="Your email" type="email" size={Size.large} initialValue={model.email} disabled />
			<PasswordInput
				label="Password"
				size={Size.large}
				value={model.password}
				onChange={handlePasswordChange}
				validation={applyValidation('password', SignupRequestValidation.password!)}
				isCopyPasswordVisible
				isGeneratePasswordVisible
				isShowPasswordVisible
				onCopyToClipboard={onCopyToClipboard}
			/>
			<ButtonGroup>
				<Button size={Size.large} variant={Variant.solid} scheme={Scheme.success} type={'submit'}>
					Set new password
				</Button>
			</ButtonGroup>
		</ForgotForm>
	);
}

const ForgotForm = styled(Form)``;
