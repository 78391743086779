import { useCallback, useState } from 'react';

export default function useModelState<T>(initialModel: T) {
	const [model, setModel] = useState<T>(initialModel);

	const setModelAttribute = useCallback(<K extends keyof T>(prop: K, value: T[K]) => {
		setModel((model) => {
			const updatedModel: T = {
				...model,
			};
			updatedModel[prop] = value;
			return updatedModel;
		});
	}, []);

	return {
		model,
		setModel,
		setModelAttribute,
	};
}
