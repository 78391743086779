import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import User from '../Models/User';
import UserService from '../Services/UserService';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import useCancelRegistry from '@cb/product-react/Hooks/UseCancelRegistry';
import FetchClient from '@cb/common-ts/FetchClient';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';

export interface UserContextValue {
	user?: User;
	isLoading: boolean;
	fetchUser: () => Promise<void>;
	setUser: (user: User) => void;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

export type UserInfoProviderProps = {
	children?: ReactNode;
};

export function UserInfoProvider(props: UserInfoProviderProps) {
	const { children } = props;
	const [user, setUserInState] = useState<User | undefined>(undefined);
	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const registerCancel = useCancelRegistry();

	const fetchUser = useCallback(async () => {
		const loader = addLoader();
		try {
			const request = FetchClient.autoAbort(UserService.GetUserInfo(), registerCancel);
			const user = await request.response;
			setUserInState(user);
		} catch (err) {
			ErrorUtils.handleError(err);
		} finally {
			removeLoader(loader);
		}
	}, [addLoader, registerCancel, removeLoader]);

	const setUser = (user: User) => {
		setUserInState(user);
	};

	useEffect(() => {
		fetchUser().catch((err) => {
			throw err;
		});
	}, [fetchUser]);

	const value: UserContextValue = {
		user,
		fetchUser,
		setUser,
		isLoading,
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default function useUserInfo() {
	const context = useContext(UserContext);
	if (context === undefined) {
		throw new Error('useUserInfoContext must be used within a UserInfoProvider');
	}
	return context;
}
